import { getSignatureDefault } from '_api/wx';

export const geWxAgentConfigMaterial = () => {
  let url = window.location.href.split('#')[0];
  getSignatureDefault(process.env.VUE_APP_SECRET, url).then(res => { // process.env.VUE_APP_CORPSECRET
    if (res.flag) {
      wx.agentConfig({
        corpid: process.env.VUE_APP_CORPID, // 必填，企业微信的corpid，必须与当前登录的企业一致
        agentid: process.env.VUE_APP_AGENTID, // 必填，企业微信的应用id （e.g. 1000247）
        timestamp: res.data.timestamp, // 必填，生成签名的时间戳
        nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
        signature: res.data.signature, // 必填，签名，见附录-JS-SDK使用权限签名算法
        jsApiList: ['sendChatMessage', 'previewImage'], // 必填，传入需要使用的接口名称 //'sendChatMessage'
        success: (res) => {
          // 回调
        },
        fail: function(res) {
          if (res.errMsg.indexOf('function not exist') > -1) {
            alert('版本过低请升级');
          }
        }
      });
    } else {
      this.$showToast(res.errorMsg);
    }
  });
};
