<template>
  <div class="section">
    <tab-composite
      ref="tabComposite"
      class="main"
      fixed-height="44"
      :tab-list="tabList"
      :load-enable="loadEnable"
      :refresh-enable="loadEnable"
      :need-btn="needBtn"
      :need-border="true"
      :tab-bar-height="40"
      @updateList="refreshLoad"
      @getIndex="getIndex"
    >
      <new-search
        slot="search"
        ref="searchRef"
        search-type="titleName"
        placeholder="搜索标题、分类"
        @getLists="refreshLoad"
      />
      <template
        v-for="(items, i) in tableData"
        #[i+1]
      >
        <div
          :key="i"
          class="list-wrapper"
        >
          <error-page
            v-if="!items.length"
            class="errorHeight"
          />
          <ul
            v-for="(item, indexs) in items"
            :key="indexs"
            class="out-box"
          >
            <card-graphic
              v-if="item.mlabel === '图文'"
              :data="item.mainObj"
              @send="sendChatMessageNews"
            />
            <card-materialFile
              v-else
              :data="item.mainObj"
              @send="sendChatMessageFile"
            />
          </ul>
        </div>
      </template>
    </tab-composite>
  </div>
</template>

<script>
import tabComposite from '_c/tab-composite';
import cardGraphic from '_c/tab-card/card-graphic';
import cardMaterialFile from '_c/tab-card/card-materialFile';
import errorPage from '_c/errorPage';
import { getMaterialWxlist, materialCount, materialWxsend } from '_api/material';
import { geWxAgentConfigMaterial } from '_lib/material-weChat';
import { debounce } from '@/lib/until';
import newSearch from '_c/search/newSearch';
export default {
  components: {
    tabComposite,
    newSearch,
    cardGraphic,
    cardMaterialFile,
    errorPage
  },
  data() {
    return {
      tableData: [[], []],
      tabList: [{
        label: '图文',
        value: 0,
        type: 1
      }, {
        label: '文件',
        value: 1,
        type: 2
      }],
      requestData: {
        name: '',
        pageNum: 1,
        pageSize: 30
      },
      needBtn: false,
      index: 0
    };
  },
  computed: {
    loadEnable() {
      return this.tableData[this.index] ? !!this.tableData[this.index].length : true;
    }
  },
  created() {
    geWxAgentConfigMaterial();
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.tabList.forEach((item, i) => {
        this.getLists(this.requestData, i, item.type, item.label);
      });
    },
    getLists(requestData, index, type, mlabel, operateType) {
      getMaterialWxlist({...requestData, type: type}).then((res) => {
        if (res.flag) {
          let tableData = [[], []];
          const list = res.data.list || [];
          if (list.length > 0) {
            if (operateType === 'upload') {
              tableData[index] = this.tableData[index].concat(list);
            } else {
              tableData[index] = list;
            }
            tableData[index].forEach(item => {
              item.mlabel = mlabel;
              item.mainObj = {
                id: item.id,
                contentType: item.contentType,
                mediaId: item.mediaId,
                coverFileUrl: item.coverFileUrl,
                contentUrl: item.contentUrl,
                title: item.title,
                remark: item.remark,
                content: `${item.materialCategoryTitle}${item.updateTime ? ' · ' + item.updateTime : ''}${item.sendCount ? ' · ' + '已发' + item.sendCount : ''} `
              };
            });
            this.$set(this.tableData, index, tableData[index]);
            this.tabList[index].tableData = tableData[index];
            if (this.$refs.tabComposite) this.$refs.tabComposite.upload = true;
          } else {
            if (requestData.pageNum === 1) {
              this.$set(this.tableData, index, []);
            } else {
              if (this.$refs.tabComposite) this.$refs.tabComposite.upload = false;
              this.$refs.tabComposite.onPullingUp();
            }
          }
        } else {
          this.$showToast(res.errorMsg);
        }
      });
    },
    getIndex(index) {
      this.index = index;
      const cleard = debounce(this.clear, 500);
      cleard();
    },
    clear() {
      for (let i in this.requestData) {
        this.requestData[i] = '';
      }
      this.requestData.pageNum = 1;
      this.requestData.pageSize = 10;
      this.tableData[this.index] = [];
      this.$refs.searchRef.value = '';
      this.refreshLoad();
    },
    refreshLoad(val, operateType) {
      let i = this.index;
      this.getLists({...this.requestData, name: val}, i, this.tabList[i].type, this.tabList[i].label, operateType);
    },
    // 重新获取 mediaId
    getMediaId(data) {
      materialWxsend(data.id).then(res => {
        if (res.flag) {
          this.sendChatMessageFile({...data, mediaId: res.data});
        } else {
          this.$showToast(res.errorMsg);
        }
      });
    },
    // wx分享消息到当前会话
    sendChatMessageFile(data) {
      if (data.mediaId !== null) {
        wx.invoke('sendChatMessage', {
          msgtype: 'file', // 消息类型，必填
          enterChat: true, // 为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
          file:
          {
            mediaid: data.mediaId // 文件的素材id
          }
        }, res => {
          if (res.err_msg === 'sendChatMessage:ok') {
            materialCount([data.id]).then(res => {
              if (!res.flag) this.$showToast(res.errorMsg);
            });
            // 发送成功
          }
        });
      } else {
        this.getMediaId(data);
      }
    },
    // wx分享消息到当前会话
    sendChatMessageNews(data) {
      // if (data.contentType === 2 && data.contentUrl === '') {
      //   this.$showToast('外部链接为空');
      //   return;
      // }
      wx.invoke('sendChatMessage', {
        msgtype: 'news', // 消息类型，必填
        enterChat: true, // 为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
        news:
        {
          link: data.contentType === 1 ? process.env.VUE_APP_RELEASE_URL + `/pages/material/index?id=${data.id}` : data.contentUrl, // H5消息页面url 必填
          title: data.title, // H5消息标题
          desc: data.remark, // H5消息摘要
          imgUrl: data.coverFileUrl // H5消息封面图片URL
        }
      }, res => {
        if (res.err_msg === 'sendChatMessage:ok') {
          materialCount([data.id]).then(res => {
            if (!res.flag) this.$showToast(res.errorMsg);
          });
          // 发送成功
        }
      });
    }
  }
};
</script>
<style  lang="scss"  scoped>
.errorHeight{
  height:calc(100vh - 44px);
}
</style>
