<template>
  <div class="card-box">
    <div class="card-cont">
      <div class="card-imgbox">
        <div class="card-img">
          <img
            :src="data.coverFileUrl"
            alt=""
          >
        </div>
      </div>
      <div class="card-content">
        <div class="card-a">
          {{ data.title }}
        </div>
        <div class="card-b">
          {{ data.remark }}
        </div>
        <div class="card-c">
          {{ data.content }}
        </div>
      </div>
      <div
        class="card-btn"
        @click="send({...data, msgtype: 'news'})"
      >
        <div class="card-btn-type" />
        <span class="card-btn-text">发送</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  methods: {
    send(data) {
      this.$emit('send', data);
    }
  }
};
</script>

<style lang="scss" scoped>
.card-box {
  width: 100%;
  background-color: $color-FFF;
  .card-cont {
    display: flex;
    justify-content: flex-start;
    padding: 16px;
    .card-imgbox {
      width: 72px;
      height: 72px;
      margin-right: 12px;
      background-color: $color-01;
      border-radius: 4px;
      img {
        width: 72px;
        height: 72px;
        border-radius: 4px;
      }
    }
    .card-content {
      flex: 1;
      overflow: hidden;
      text-align: left;
      .card-a {
        margin-bottom: 4px;
        color: #23252E;
        font-size: $font-16;
        line-height: 20px;
        @extend .one-ellipsis;
      }
      .card-b {
        margin-bottom: 14px;
        color: #999BA3;
        font-size: $font-14;
        line-height: 18px;
        @extend .one-ellipsis;
      }
      .card-c{
        color: #999BA3;
        font-size: 12px;
        line-height: 16px;
      }
    }
    .card-btn{
      align-self: center;
      width: 40px;
      height: 40px;
      border-left:1px solid #EAEBED;
      .card-btn-type{
        width: 20px;
        height: 20px;
        margin-bottom: 4px;
        margin-left: 17px;
        background-image: url('~@/assets/img/icon_send@2x.png');
        background-size:20px 20px;
      }
      .card-btn-text{
        margin-left: 15px;
        color: $color-theme;
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
}
</style>
