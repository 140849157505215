<template>
  <div class="card-box">
    <div class="card-cont">
      <div class="card-imgbox">
        <div class="card-img">
          <img
            :src="fileTypeImg()"
            alt=""
          >
        </div>
      </div>
      <div class="card-content">
        <div class="card-a">
          {{ data.title }}
        </div>
        <div class="card-b">
          {{ data.content }}
        </div>
      </div>
      <i class="card-vertical" />
      <div
        class="card-btn"
        @click="send({...data, msgtype: 'file'})"
      >
        <div class="card-btn-type" />
        <span class="card-btn-text">发送</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      fileTypeImgObj: {
        'doc': require('../../assets/img/upload/icon_ format_word.png'),
        'docx': require('../../assets/img/upload/icon_ format_word.png'),
        'xls': require('../../assets/img/upload/icon_ format_excel.png'),
        'xlsx': require('../../assets/img/upload/icon_ format_excel.png'),
        'pdf': require('../../assets/img/upload/icon_ format_pdf.png'),
        'PDF': require('../../assets/img/upload/icon_ format_pdf.png'),
        'png': require('../../assets/img/upload/icon_ format_pic.png'),
        'jpg': require('../../assets/img/upload/icon_ format_pic.png'),
        'zip': require('../../assets/img/upload/icon_ format_zip.png'),
        'else': require('../../assets/img/upload/icon_ format_else.png')
      }
    };
  },
  methods: {
    fileTypeImg() {
      if (this.data.contentUrl) {
        let type = this.data.contentUrl.match(/([^.]+)$/)[1];
        for (let i in this.fileTypeImgObj) {
          if (type === i) {
            return this.fileTypeImgObj[i];
          }
        }
        return this.fileTypeImgObj['else'];
      }
    },
    send(data) {
      this.$emit('send', data);
    }
  }
};
</script>

<style lang="scss" scoped>
.card-box {
  width: 100%;
  background-color: $color-FFF;
  .card-cont {
    display: flex;
    justify-content: flex-start;
    padding: 16px;
    .card-imgbox {
      flex: 0 0 40px;
      width: 40px;
      height:40px;
      margin-right: 12px;
      background-color: $color-01;
      border-radius: 4px;
      img {
        width: 40px;
        height: 40px;
        border-radius: 4px;
      }
    }
    .card-content {
      flex: 1 1 auto;
      overflow: hidden;
      text-align: left;
      .card-a {
        margin-bottom: 4px;
        color: #23252E;
        font-size: $font-16;
        line-height: 20px;
        @extend .one-ellipsis;
      }
      .card-b {
        color: #999BA3;
        font-size: $font-14;
        line-height: 18px;
        @extend .one-ellipsis;
      }
    }
    .card-vertical{
      align-self: center;
      width: 1px;
      height:24px;
      background-color: #EAEBED;
    }
    .card-btn{
      flex: 0 0 40px;
      align-self: center;
      width: 40px;
      height: 40px;
      .card-btn-type{
        width: 20px;
        height: 20px;
        margin-bottom: 4px;
        margin-left: 17px;
        background-image: url('~@/assets/img/icon_send@2x.png');
        background-size:20px 20px;
      }
      .card-btn-text{
        margin-left: 15px;
        color: $color-theme;
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
}
</style>
