import { baseRequest } from './index';

/**
 * 企业微信素材列表
 * @params data Object
 * @returns MenusInfo
*/
export const getMaterialWxlist = (data) => baseRequest.post('/material/material/wxlist', data);

/**
 * 发送成功,计数接口
 * @params id Number
 * @returns MenusInfo
*/
export const materialCount = (idList) => baseRequest.post('/material/material/count', {idList});

/**
 * 企业微信文件发送按钮
 * @params id Number
 * @returns MenusInfo
*/
export const materialWxsend = (id) => baseRequest.post('/material/material/wxsend', {id});

/**
 * 图文详情接口
 * @params id Number
 * @returns MenusInfo
*/
export const getMaterialDetail = (id) => baseRequest.post('/material/material/detail', {id});
